import { Component, OnInit, Input } from '@angular/core';
import { menus } from './menu-element';
import { Ordermenus } from './ordersmenu';
import { Masterdatamenus } from './masterdata';
import { Configurablesdatamenus } from './configurables';
import { Creditmenus } from './credit';
import { Feedbackmenus } from './feedback';
import { Bowsermenus } from './bowserenrollemnt';
import { Reportsmenus } from './reports';
import { Subadminsmenus } from './subadmin';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

    @Input() iconOnly:boolean = false;
    public menus = menus;
    public Ordermenus = Ordermenus;
    public Masterdatamenus = Masterdatamenus;
    public Configurablesdatamenus = Configurablesdatamenus;
    public Creditmenus = Creditmenus;
    public Bowsermenus = Bowsermenus;
    public Feedbackmenus = Feedbackmenus;
    public Reportsmenus = Reportsmenus;
    public Subadminsmenus = Subadminsmenus;

    accessNamesList=[];
    loginuserRole:any;


    adminmenu=true;
    Orderstrue=false;
    Masterdatatrue=false;
    Configurablestrue=false;
    bowsertrue=false;
    creditstrue=false;
    feedbacktrue=false;
    reportstrue=false;
    // subadmintrue=false;


    constructor() { }

    ngOnInit() {
      if(JSON.parse(localStorage.getItem('accessNames'))!=null){
      this.accessNamesList = JSON.parse(localStorage.getItem('accessNames'));
      this.loginuserRole = JSON.parse(localStorage.getItem('loginuserRole'));
      console.log("ACCESSLIST"+this.accessNamesList)
      console.log("MENULIST"+this.Ordermenus)
      }
     
      if(this.loginuserRole=="SUPERADMIN"){
        this.adminmenu=true;
      }

      if(this.accessNamesList.includes('Orders')) {
      this.Orderstrue=true;
      this.adminmenu=false;
     }

      if(this.accessNamesList.includes('Master Data')) {
        this.Masterdatatrue=true;
        this.adminmenu=false;
      }
     
      if(this.accessNamesList.includes('Configurables')) {
        this.Configurablestrue=true;
        this.adminmenu=false;
      }

     
      if(this.accessNamesList.includes('Bowser Enrollment')) {
        this.bowsertrue=true;
        this.adminmenu=false;
      }

      if(this.accessNamesList.includes('FeedBack/Grievances')) {
        this.feedbacktrue=true;
        this.adminmenu=false;
      }

      if(this.accessNamesList.includes('Credit For Customers')) {
        this.creditstrue=true;
        this.adminmenu=false;
      }

      if(this.accessNamesList.includes('Reports')) {
        this.reportstrue=true;
        this.adminmenu=false;
      }

  


    }







}
