export const environment = {
  production: false,
  firebase: {
  apiKey: "AIzaSyDPhJh6oIHtQOWdSJDMD99Xhh9j4LVa_nM",
  authDomain: "dezal-user-web.firebaseapp.com",
  projectId: "dezal-user-web",
  storageBucket: "dezal-user-web.appspot.com",
  messagingSenderId: "830319822291",
  appId: "1:830319822291:web:3d84c0ed4a8da74f8d9b26",
  measurementId: "G-WC920QRGR8"
  },
  mailApi: '/assets/list.json'
};
