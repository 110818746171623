import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from '../../service/cache.service';
import { GetListService } from '../../service/get-list.service';
@Component({
  selector: 'app-edit-subadmin',
  templateUrl: './edit-subadmin.component.html',
  styleUrls: ['./edit-subadmin.component.scss']
})
export class EditSubadminComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  @Output() editingsubadminoutput = new EventEmitter();
  accessList: any;
  accesscheckboxes: any;
  options: any = []
  name: any;
  username: any;
  password: any;
  accesslistnames: any;
  pageNO=0;
  subadminList: any;
  nameslist: any;
  newArray: any;
  constructor(public activeModal: NgbActiveModal,public router: Router,
    private formBuilder: FormBuilder,private reactiveFormbuilder:ReactiveFormsModule,
    private serverService: GetListService,
     public cacheService: CacheService,public activateRouter: ActivatedRoute,) {
      
      }
  ngOnInit() {
    this.getAccessLists();
    // var sub = this.activateRouter
    // .queryParams
    // .subscribe(params => {
    //   this.name =params['name'];
    //   this.username=params['username'];
    //   this.password=params['password'];
    //   this.accesslistnames=params['accesslistnames'];
    console.log(this.inputDataInModalComponent);
this.name=this.inputDataInModalComponent.userName;
this.username=this.inputDataInModalComponent.adminID;
this.password=this.inputDataInModalComponent.passWord;
this.accesslistnames=this.inputDataInModalComponent.accessNames;

// for(let accesnames of this.accesslistnames){
//   this.nameslist=accesnames;
//   console.log(this.nameslist);

// }
console.log(this.accesslistnames);
    // });
  }
  closeclicked(){
    this.activeModal.close();
  }
  setCheckbox(event: any, index: number) {
    if(!this.options.includes(event)){
      this.options = [this.options, event];
    }else {
      this.options = this.options.filter((item) => item !== event);
    }
  }


  



  getAccessLists() {
    var Obj = {
    }
    this.serverService.getAccessList(Obj).subscribe(
      rdata => {
        if (rdata) {
          this.accessList = rdata.data;
          // this.cacheService.cachedAccessList;
          // console.log(this.accessList);
         this.accesscheckboxes=this.accessList.accessNames;

        //  for (var i = 0; i < this.accesscheckboxes.length; i++) {

        //   var ismatch = false; // we haven't found it yet
    
        //   for (var j = 0; j < this.accesslistnames.length; j++) {
    
        //     if (JSON.stringify(this.accesscheckboxes) == JSON.stringify(this.accesslistnames)) {
        //       // we have found this.officeLIST[i]] in this.office, so we can stop searching
        //       ismatch = true;
        //       this.accesscheckboxes[i].checked = true;//  checkbox status true
        //       this.newArray.push(this.accesscheckboxes[i]);
        //       break;
        //     }//End if
        //     // if we never find this.officeLIST[i].office_id in this.office, the for loop will simply end,
        //     // and ismatch will remain false
        //   }
        //   // add this.officeLIST[i] to newArray only if we didn't find a match.
        //   if (!ismatch) {
        //     this.accesscheckboxes[i].checked = false;//  checkbox status false
        //     this.newArray.push(this.accesscheckboxes[i]);
        //   } //End if
        // }
        // console.log(this.newArray);


          //  console.log(this.accesscheckboxes);
          //  for(let checkboxaccesslist of this.accesscheckboxes){
          //    for(let names of this.accesslistnames){
          //     // console.log( checkboxaccesslist.includes("MasterData"))
          //     console.log( checkboxaccesslist.includes(names))

          //    }
            
          //  }
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }


  getSubAdminLists() {
    var Obj = {
      "pageNO": this.pageNO,
    }
    this.serverService.getSubAdminList(Obj).subscribe(
      rdata => {
        if (rdata) {
          this.subadminList = this.cacheService.cachedSubAdmin;
          this.pageNO = this.pageNO + 1;
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }
  editsubadminclicked(){
    this.EditSubadmin();
    this.activeModal.close();
  }
  EditSubadmin() {
    var Obj = {
      "userName": this.name,
      "passWord": this.password,
       "accessNames":this.options
    }
    this.serverService.editSubadmin(Obj).subscribe(
      rdata => {
        if (rdata) {
          alert("Edit subadmin Suceessfull!");
          // window.location.reload();
          this.editingsubadminoutput.emit()
          this.activeModal.close();
        }
        else {
          // this.cacheService.displaySpinner = false;
        }
      },
      error => {
        // this.cacheService.displaySpinner = false;
      }
    )
  }
}