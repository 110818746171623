import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
// import { ToastrModule } from 'ngx-toastr';
// import { ChartsModule } from 'ng2-charts';

//  import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { AgmCoreModule } from '@agm/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule }  from '@angular/forms';
import { ConfigService } from './service/config.service';
import { CacheService } from './service/cache.service';
import { DataService} from './service/data.service';
import { PathLocationStrategy } from '@angular/common';
import { APP_BASE_HREF } from '@angular/common';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { DatePipe } from '@angular/common';
// import {ImagecropperShowDialogComponent} from './share-your-testimony/imagecropper-show-dialog/imagecropper-show-dialog.component'
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule, MatSelectModule, MatInputModule, MatIconModule, MatButtonModule, MatTabsModule, MatDialog, MatDialogModule } from '@angular/material';

import { ModalModule } from 'ngx-bootstrap/modal';
// import {ImagecropperShowDialogComponent} from './adddisease/imagecropper-show-dialog/imagecropper-show-dialog.component';
// import { SubadminsComponent } from './subadmins/subadmins/subadmins.component';
// import { ReportComponent } from './report/report/report.component';
// import { CreditforcustomersComponent } from './creditforcustomers/creditforcustomers/creditforcustomers.component';
// import { FeedbackcontactusComponent } from './feedback/feedbackcontactus/feedbackcontactus.component';
// import { ConfigurablesComponent } from './configurables/configurables/configurables.component';
// import { AllOrdersComponent } from './all-orders/all-orders.component';
// import { MasterdatauploadComponent } from './dezalcomponents/masterdataupload/masterdataupload.component';
// import { LanguageListComponent } from './language/language-list/language-list.component';
// import { AddvideotodiseaseComponent } from './diseasevideos/addvideotodisease/addvideotodisease.component';
// import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

import { CreatesubadminComponent } from './subadmins/createsubadmin/createsubadmin.component';
import { SubadminsModule } from './subadmins/subadmins.module';

import { EditSubadminComponent } from './subadmins/edit-subadmin/edit-subadmin.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material';
import { MatNativeDateModule } from '@angular/material/core';
import { AddAddressComponent } from './addaddress/add-address.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MapsDialogComponent } from './maps-dialog/maps-dialog.component';

//////////////////////////////////firebase////////////
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { environment } from '../environments/environment';


import { AgmCoreModule } from '@agm/core';
import { ChangeOrderDateComponent } from './change-order-date/change-order-date.component';
import { AddAddressDeliverypageComponent } from './add-address-deliverypage/add-address-deliverypage.component';
import { ChangeSelectedDateComponent } from './change-selected-date/change-selected-date.component';
import { PaybalanceComponent } from './paybalance/paybalance.component';



@NgModule({
  declarations: [
    AppComponent,
    AddAddressComponent,
    MapsDialogComponent,
    MapsDialogComponent,
    ChangeOrderDateComponent,
    AddAddressDeliverypageComponent,
    ChangeSelectedDateComponent,

   

    PaybalanceComponent,
    

    // HomepagecomponentComponent,
    // AuthenticationComponent,
    // ImagecropperShowDialogComponent,
    // SubadminsComponent,
    // ReportComponent,
    // CreditforcustomersComponent,
    // FeedbackcontactusComponent,
    // ConfigurablesComponent,
    // AllOrdersComponent,
    // MasterdatauploadComponent,

    
 

  ],
  imports: [
    BrowserModule,
    LazyLoadModule,
    CoreModule,
    HttpClientModule,
    // ChartsModule,
    HttpModule,
    ModalModule.forRoot(),
    // NgxYoutubePlayerModule.forRoot(),
    MatInputModule,
    // NgbModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    FormsModule, ReactiveFormsModule,
    MatTabsModule,
    SlickCarouselModule,
    AngularMultiSelectModule,
    MatDialogModule,
    
   // ToastrModule.forRoot({ timeOut: 5000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,}),
  
    ImageCropperModule,
    NgbModule.forRoot(),

    BrowserAnimationsModule,
    SubadminsModule,
    MatDatepickerModule,MatCardModule,MatNativeDateModule,

    AngularFireModule,AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,AngularFirestoreModule,
    

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCyoaT-kXoh6o7xEJDXK4JlSOYPraDHJsI',
      libraries: ['places']
    })
    // AIzaSyDdCbj1C_TvcKSjF_qYNm_CQw-4666oXl8

  ],
  // entryComponents:[ImagecropperShowDialogComponent,
  entryComponents:[CreatesubadminComponent,AddAddressComponent,AddAddressDeliverypageComponent,ChangeSelectedDateComponent,MapsDialogComponent,ChangeOrderDateComponent,PaybalanceComponent,
    EditSubadminComponent],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
  ConfigService,
   DataService,
   CacheService,
    DatePipe
  ],

    
  bootstrap: [AppComponent]
})
export class AppModule { }
