import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsDialogComponent } from '../maps-dialog/maps-dialog.component';
import { CacheService } from '../service/cache.service';
import { GetListService } from '../service/get-list.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']

})
export class AddAddressComponent implements OnInit {

  @Output() addingnewaddress = new EventEmitter();
  towndropdownList = [];
  townselectedItems = [];
  townpagination=0;
  towndropdownSettings = {};
  exceedsTownErrorMsg=false;
  exceedsErrorMsgTown=false;
  mandaldropdownList = [];
  mandalpagination=0;
  mandalselectedItems = [];
  mandaldropdownSettings = {};
  exceedsErrorMsg=false;
  districtdropdownList = [];
  districtpagination=0;
  districtselectedItems = [];
  districtdropdownSettings = {};
  inputTown: string;
  inputMandal: string;
  addressform:FormGroup;
  isSubmitted=false;
  inputcompanyname: any;
  companyGSTN=null;
  pinlocation=null;
  completeaddress: any;
  longitude: number;
  latitude: number;
  latlongarray=[];

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private serverService: GetListService,private modalService: NgbModal,
     public cacheService: CacheService,public form: FormBuilder
     ) {
      this.addressform = this.form.group({
        inputDistrict: [null, Validators.compose([Validators.required])],
        inputMandal: [null, Validators.compose([Validators.required])],
        inputTown: [null, Validators.compose([Validators.required])],
        inputcompanyname: [null, Validators.compose([Validators.required])],
        completeaddress:[null, Validators.compose([Validators.required])],
         pinlocation:[null, Validators.compose([Validators.required])],
         companygstn: [null, Validators.compose(null)]
      });
   }

  ngOnInit() {
    this.towndropdownSettings = {
      text: "Select Town",
      singleSelection: true,
      searchPlaceholderText: "Search Town",
      enableCheckAll: false,
      enableFilterSelectAll: false,
      maxHeight: '300',
      classes: "form-control shadow-z-1 ml-0",
      enableSearchFilter: true,
      showCheckbox: true,
      position: "bottom",
      labelKey: 'townName',
      primaryKey: 'townID',
      searchAutofocus: false
    };
    this.mandaldropdownSettings = {
      text: "Select Mandal",
      singleSelection: true,
      searchPlaceholderText: "Search Mandal",
      enableCheckAll: false,
      enableFilterSelectAll: false,
      maxHeight: '300',
      classes: "form-control shadow-z-1 ml-0",
      enableSearchFilter: true,
      showCheckbox: true,
      position: "bottom",
      labelKey: 'mandalName',
      primaryKey: 'mandalID',
      searchAutofocus: false
    };
    this.districtdropdownSettings = {
      text: "Select District",
      singleSelection: true,
      searchPlaceholderText: "Search District",
      enableCheckAll: false,
      enableFilterSelectAll: false,
      maxHeight: '300',
      classes: "form-control shadow-z-1 ml-0",
      enableSearchFilter: true,
      showCheckbox: true,
      position: "bottom",
      labelKey: 'districtName',
      primaryKey: 'districtID',
      searchAutofocus: false
    };
  }





  addressformFunvalid(formdata){
    this.isSubmitted = true;
    if (this.addressform.valid) {
      console.log("Adding Address Submit", this.addressform.value);
     this.addnewAddress();
    }
  }


  closeclicked(){
    this.activeModal.close();
  }
  OnItemDeSelect(item: any) {
    console.log(item);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onItemSelectTown(item: any) {
    this.cacheService.selectedTownid = item.townID;
    this.cacheService.selectedTownname = item.townName;
  }

  onItemSelectMandal(item: any) {
    this.cacheService.selectedMandalid = item.mandalID;
    this.cacheService.selectedMandalname = item.mandalName;
  }
  onItemSelectdistrict(item: any) {
    this.cacheService.selectedDistrictid = item.districtID;
    this.cacheService.selectedDistrictname = item.districtName;

  }

  townclicked() {
    if (this.cacheService.selectedMandalid == null) {
      console.log("print thissss");
      this.exceedsTownErrorMsg = true
    } else {
      this.exceedsTownErrorMsg = false
    }
    this.exceedsErrorMsgTown = false;
    this.cacheService.cachedTownsbymandallist = [];
    this.towndropdownList = [];
    this.townpagination = 0;
    this.populatetownlistbymandal();
  }

  mandalclicked() {
    // console.log(this.cacheService.selectedDistrictid!=null);
    if (this.cacheService.selectedDistrictid == null) {
      this.exceedsErrorMsg = true;
    } else {
      // console.log("selected");
      // console.log(this.selectedDistrictid);
      this.exceedsErrorMsg = false;
      this.exceedsTownErrorMsg = false;
    }
    this.inputTown="";
   
    this.townselectedItems=[];
    this.exceedsErrorMsgTown = false;
    this.exceedsTownErrorMsg = false;
    this.cacheService.cachedMandallist = [];
    this.mandaldropdownList = [];
    this.mandalpagination = 0;
    this.populatemandallist();
   
    this.inputTown="";
  }


  districtclicked() {
    this.inputMandal="";
    this.inputTown="";
    
    this.exceedsErrorMsg = false;
    this.cacheService.selectedMandalid = "";
    this.cacheService.selectedMandalname = "";
    this.mandalselectedItems=[];
   
    this.townselectedItems=[];
    this.cacheService.cachedDistrictlist = [];
    this.districtdropdownList = [];
    this.districtpagination = 0;
    this.populatedistrictlist();
  
  }

  onScrollDownTown(event) {
    this.townpagination = this.townpagination + 1;
    this.populatetownlistbymandal();
  }


  onScrollDownMandal(event) {
    this.mandalpagination = this.mandalpagination + 1;
    this.populatemandallist();
  }


  onScrollDownDistrict(event) {
    this.districtpagination = this.districtpagination + 1;
    this.populatedistrictlist();
  }

  addnewAddress() {
    var Obj = {

    //   if (pinlocationlatlong.size()>0) {
    //     // js.put("loc" , new JSONArray(pinlocationlatlong));
    // } else {
    //     // js.put("loc" , new JSONArray(location));
    // }
          "loc":this.latlongarray,
      "userID": JSON.parse(localStorage.getItem('logedinUserID')),
          "companyName": this.inputcompanyname,
          "companyGSTN":this.companyGSTN,
          "companyAddress":this.completeaddress,
          "districtID": this.cacheService.selectedDistrictid,
      "districtName": this.cacheService.selectedDistrictname,
      "mandalID": this.cacheService.selectedMandalid,
      "mandalName": this.cacheService.selectedMandalname,
      "townID": this.cacheService.selectedTownid,
      "townName": this.cacheService.selectedTownname,
           "price":"",
           "completeAddress":this.pinlocation
           

    }
    this.serverService.addNewAddress(Obj).subscribe(
      rdata => {
        if (rdata.success) {
  alert("Address Added successfully");
  this.addingnewaddress.emit()
  this.activeModal.close();
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }




  populatetownlistbymandal() {
    var Obj = {
      "mandalID": this.cacheService.selectedMandalid,
      "pageNO": this.townpagination
    }
    this.serverService.getTownListbymandal(Obj).subscribe(
      rdata => {
        if (rdata) {
          this.towndropdownList = this.cacheService.cachedTownsbymandallist;
          // this.edittowndropdownList = this.cacheService.cachedTownsbymandallist;
          console.log(this.towndropdownList);
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }


  populatemandallist() {
    var Obj = {
      "districtID": this.cacheService.selectedDistrictid,
      // this.cacheService.selectedDistrictid,
      "pageNO": this.mandalpagination
    }
    this.serverService.getMandalList(Obj).subscribe(
      rdata => {
        if (rdata) {
          this.mandaldropdownList = this.cacheService.cachedMandallist;
          // this.editmandaldropdownList = this.cacheService.cachedMandallist;
          console.log(this.mandaldropdownList);
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }


  populatedistrictlist() {
    var Obj = {
      "pageNO": this.districtpagination
    }
    this.serverService.getDistrictList(Obj).subscribe(
      rdata => {
        if (rdata) {
          this.districtdropdownList = this.cacheService.cachedDistrictlist;
          // this.editdistrictdropdownList = this.cacheService.cachedDistrictlist;
          console.log(this.districtdropdownList);
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }




  // map dialog code 
  openmapdialog($event) {
    const modalRef = this.modalService.open(MapsDialogComponent,{windowClass: 'my-class'});
    modalRef.componentInstance.mapsoutput.subscribe((emmitedValue) => {

  this.pinlocation=emmitedValue;
  console.log( "printtttttttttttttt" + this.pinlocation )
    });

    modalRef.componentInstance.mapslatitudeoutput.subscribe((emittedValue) => {
      
      this.latitude=emittedValue;
      console.log(this.latitude);
      this.latlongarray.push(this.latitude);

        });


        modalRef.componentInstance.mapslongitudeoutput.subscribe((emittedValue) => {
      
          this.longitude=emittedValue;
          console.log(this.longitude);
          this.latlongarray.push(this.longitude);


            });

           
            
                      
    modalRef.componentInstance.imageChangedEvent = $event;



    console.log("print lat long"+ this.latlongarray);
  }

}
