import { GoogleMap } from '@agm/core/services/google-maps-types';
import { AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {} from 'google.maps';
import {Loader} from '@googlemaps/js-api-loader'
import { LoadedRouterConfig } from '@angular/router/src/config';
import { MapsAPILoader } from '@agm/core';
import { LocationService } from '../service/location.service';

@Component({
  selector: 'app-maps-dialog',
  templateUrl: './maps-dialog.component.html',
  styleUrls: ['./maps-dialog.component.scss'],
})
export class MapsDialogComponent implements OnInit {
  @Output() mapsoutput = new EventEmitter();
  @Output() mapslatitudeoutput = new EventEmitter();

  @Output() mapslongitudeoutput = new EventEmitter();

  


zoom: number;
address: string;
private geoCoder;

@ViewChild('search')
public searchElementRef: ElementRef;
  latitude: number;
  longitude: number;
  web_site: string;
  name: string;
  zip_code: string;
  lng: number;
  lat: number;

constructor(
  private mapsAPILoader: MapsAPILoader,public form: FormBuilder,public activeModal: NgbActiveModal,
  private ngZone: NgZone, public locationService:LocationService
) { 
  // if (navigator)
  // {
  // navigator.geolocation.getCurrentPosition( pos => {
  //     this.lng = +pos.coords.longitude;
  //     this.lat = +pos.coords.latitude;
  //     console.log("current latlong" + this.lng,this.lat);
  //   });
  // }

}

closeclicked(){
  this.activeModal.close();
}

coordinates;

ngOnInit() {
  this.mapsAPILoader.load().then(() => {
    this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder;
  });
  // this.locationService.getPosition().then(pos=>
  //   {
  //      console.log(`Positon:  ${pos.lat}  , ${pos.lng}`);
  //   });
  

}
private setCurrentLocation() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      console.log("print latandlon" + this.latitude,this,this.longitude)
      this.zoom = 17;
      this.getAddress(this.latitude, this.longitude);
    });
  }
  
}



locationclicked(){
  this.mapsAPILoader.load().then(() => {
    this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder;
  });
}

getAddress(latitude, longitude) {
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        this.zoom = 17;
        this.name=results[0].name;
        this.address =  results[0].formatted_address;
        
        console.log("address"+ this.name);
      } else {
        window.alert('No results found');
      }
    } else {
      window.alert('Geocoder failed due to: ' + status);
    }
  
  });
}



mapClicked($event){
  console.log($event)
  this.latitude = $event.coords.lat;
  this.longitude = $event.coords.lng;
  this.getAddress(this.latitude, this.longitude);
  console.log(this.latitude,this.longitude)
}




confirmpinnedlocation(){
console.log("dcadaeda" + this.latitude);
console.log("dcadaeda" + this.longitude);

  this.mapsoutput.emit(this.address);
  this.mapslatitudeoutput.emit(this.latitude);
  this.mapslongitudeoutput.emit(this.longitude);

  this.activeModal.close();

}
  }
 