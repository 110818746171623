import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from '../../service/cache.service';
import { GetListService } from '../../service/get-list.service';
 '@angular/forms';
@Component({
  selector: 'app-createsubadmin',
  templateUrl: './createsubadmin.component.html',
  styleUrls: ['./createsubadmin.component.scss']
})
export class CreatesubadminComponent implements OnInit {
  @Output() creatingsubadminoutput = new EventEmitter();
  // form: FormGroup;
  // webData = [
  //   { id: 1, name: 'Orders' },
  //   { id: 2, name: 'Master Data' },
  //   { id: 3, name: 'Reports' },
  //   { id: 4, name: 'Enrollment' }
  // ];
  name: any;
  password: any;
  username: any;
  accessList: any;
  accesscheckboxes: any;
  options: any = []
  pageNO=0;
  subadminList: any;
  // get accesslistFormArray() {
  //   return this.form.controls.orders as FormArray;
  // }
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,private reactiveFormbuilder:ReactiveFormsModule,
    private serverService: GetListService,
     public cacheService: CacheService
     ) {
    // this.form = this.formBuilder.group({
    //   orders: new FormArray([])
    // });
    // this.addCheckboxesToForm();
   }
  ngOnInit() {
    this.getAccessLists();
  }
  closeclicked(){
    this.activeModal.close();
  }
  // private addCheckboxesToForm() {
  //   //  this.accesscheckboxes.map((value, index) => this.accesslistFormArray.push(new FormControl(false)));
  // }
  // submit() {
  //   const selectedOrderIds = this.form.value.orders
  //     .map((checked, i) => checked ? this.webData[i].id : null)
  //     .filter(v => v !== null);
  //   console.log(selectedOrderIds);
  // }
  setCheckbox(event: any, index: number) {
    if(!this.options.includes(event)){
      this.options = [...this.options, event];
    }else {
      this.options = this.options.filter((item) => item !== event);
    }
  }
  createsubadminclicked(){
    this.createSubAdmin();
    this.activeModal.close();
    this.getAccessLists();
  }
  getSubAdminLists() {
    var Obj = {
      "pageNO": this.pageNO,
    }
    this.serverService.getSubAdminList(Obj).subscribe(
      rdata => {
        if (rdata) {
          this.subadminList = this.cacheService.cachedSubAdmin;
          this.pageNO = this.pageNO + 1;
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }
  createSubAdmin() {
    var Obj = {
        "userName": this.name,
          "passWord": this.password,
          "role":"ADMIN",
          "adminID":this.username,
           "accessNames":this.options
    }
    this.serverService.createSubAdmin(Obj).subscribe(
      rdata => {
        if (rdata.success) {
  alert("subadmin created successfully");
  this.creatingsubadminoutput.emit()
  this.activeModal.close();
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }
  getAccessLists() {
    var Obj = {
    }
    this.serverService.getAccessList(Obj).subscribe(
      rdata => {
        if (rdata) {
          this.accessList = rdata.data;
          // this.cacheService.cachedAccessList;
          console.log(this.accessList);
         this.accesscheckboxes=this.accessList.accessNames;
           console.log(this.accesscheckboxes);
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }
}