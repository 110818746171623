import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  //public userID:any;

  public displaySpinner:any = false;
 
  public CachedDiseasesList:any=[];
  public CachedQuestionsList:any;
  public CachedOptionsList:any;
  public CachedAnalysisList:any=[];
  public resultdiseaseid:any;
  public selectedreporttype:any;
  public selectedoptionsarrayid:any=[];
  public selectedoptionsarraynames:any=[];
  public cachedoptionsobject:any=[];
  public cachedFaqs:any=[];
  public cachedContacts:any=[];
  public cachedDiseaseVideoslist:any=[];
  public cachedLanguageslist:any=[];
  public cachedSubAdmin:any=[];
  public cachedAccessList:any=[];
  public cachedPriorityList:any=[];
  public cachedInactiveList:any=[];
  public cachedNormalList:any=[];
  public cachedBowserSearchList:any=[];
  public cachedallcustomersList:any=[];
  public cachedcreditsEnabledList:any=[];
  public cachedcreditsDisabledList:any=[];
  public cachedBowserdetailsList:any=[];
  public cacheduserSearchList:any=[];
  public enableanddisble:any=[];


  public cachedUnassignedOrderList:any=[];
  public cachedInProgressOrderList:any=[];
  public cachedCompletedOrderList:any=[];
  public cachedCancelleddOrderList:any=[];
  public cachedIndustrieslist:any=[];
  public cachedProductslist:any=[];

  public cachedAllFeedbackList:any=[];
  public cachedBowserAllFeedbackList:any=[];

  public cachedTodayFeedbackList:any=[];
  public cachedBowserTodayFeedbackList:any=[];

  public cachedThirtyDaysFeedbackList:any=[];
  public cachedBowserThirtyDaysFeedbackList:any=[];


  public selectedDistrictid:any;
  public selectedDistrictname:any;
  public selectedMandalid:any;
  public selectedMandalname:any;
  public selectedTownname:any;
  public selectedTownid:any;
  public selectedVillageid:any;
  public selectedVillagename:any;
  public selectededitTownname:any;
  public selectededitTownid:any;
  public selectededitVillageid:any;
  public selectededitVillagename:any;

  public cachedTownslist:any=[];
  public cachedDistrictlist:any=[];
  public cachedMandallist:any=[];
  public cachedTownsbymandallist:any=[];

  // public selectedDistrictname:any=[];
  // public selectedDistrictid:any=[];
  // public selectedMandalname:any=[];
  // public selectedMandalid:any=[];

  // public selectedsettlementDistrictname:any=[];
  // public selectedsettlementDistrictid:any=[];

  // public selectedsettlementMandalname:any=[];
  // public selectedsettlementMandalid:any=[];
  // public selectedsettlementTownname:any=[];
  // public selectedsettlementTownid:any=[];
  // public selectedcreditDistrictid:any=[];
  // public selectedcreditDistrictname:any=[];
  // public selectedcreditMandalid:any=[];
  // public selectedcreditMandalname:any=[];
  // public selectedcreditTownid:any=[];
  // public selectedcreditTownname:any=[];

  public cachedsalesReportData=[];
  public cachedsettlementReportData=[];
  public cachedcreditReportData=[];
  public cachedVillagelist:any=[];
  public cachedTimeSlotsList:any=[];
  public cachedCsvData:any=[];
  public cachedBowsersList:any=[];

  public cachedPendingOrdersDatesList:any=[];


  public cachedBanners:any=[];
  public cachedAddressesList:any=[];

public cachedProfileDetailslist:any=[];
public cachedaddresslistdetails:any=[];
public cachedmonthslist:any=[];
public cachedorderlist:any=[];
public cachedorderdateslist:any=[];
public cachedorderdetails:any=[];
public cachedordervolume:any=[];

  selectedIndustryid: any;
  selectedIndustryname: any;
  selectedIndustrycode: any;
  selectedProductid="";
  selectedProductname: any;

}