import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubadminsRoutingModule } from './subadmins-routing.module';
import { SubadminsComponent } from './subadmins/subadmins.component';
import { CreatesubadminComponent } from './createsubadmin/createsubadmin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditSubadminComponent } from './edit-subadmin/edit-subadmin.component';

@NgModule({
  imports: [
    CommonModule,
    SubadminsRoutingModule,
    ReactiveFormsModule,FormsModule
  ],
  declarations: [SubadminsComponent, CreatesubadminComponent, EditSubadminComponent],
  entryComponents: [CreatesubadminComponent,EditSubadminComponent],
})
export class SubadminsModule { }
