import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from '../service/cache.service';
import { GetListService } from '../service/get-list.service';


@Component({
  selector: 'app-change-selected-date',
  templateUrl: './change-selected-date.component.html',
  styleUrls: ['./change-selected-date.component.scss']
})
export class ChangeSelectedDateComponent implements OnInit {
  @Output() changedselectedFormattedDate = new EventEmitter();
  @Output() changedselectedMonthInDigitsDate = new EventEmitter();
  @Output() changedselectedMonthInStringDate = new EventEmitter();
  @Output() changedselectedYearDate = new EventEmitter();

  
  

  // @Input() inputDataInModalComponent: any;
  // @Input() inputorderIDModalComponent: any;


  
  deliverydate: any;
  orderid: any;
  selectedFormattedDate: string;
  selectedMonthInDigitsDate: string;
  selectedYearDate: string;
  changeorderdateoutput: any;

  constructor(public activeModal: NgbActiveModal,private datePipe: DatePipe,
    public cacheService: CacheService,private getListService: GetListService) { }
  selectedDate: any;
  datesToMark: any;
  upcomingCalendarEvents: any;
  planModel: any;
  changedSelectedDateFrmt: any;
  selectedMonthInStringDate: any;
  ngOnInit() {
    console.log(this.deliverydate);


    this.planModel= {selectedDate: new Date() };
    this.selectedFormattedDate=this.datePipe.transform(this.planModel.selectedDate,'MMMM-dd-yyyy');
    console.log(this.selectedFormattedDate);
    this.selectedMonthInStringDate=this.datePipe.transform(this.planModel.selectedDate,'MMMM');
    console.log(this.selectedMonthInStringDate);

    this.selectedMonthInDigitsDate=this.datePipe.transform(this.planModel.selectedDate,'MM');
    console.log(this.selectedMonthInDigitsDate);

    this.selectedYearDate=this.datePipe.transform(this.planModel.selectedDate,'yyyy');
    console.log(this.selectedYearDate);
  }
  closeclicked(){
    this.activeModal.close();
  }

  minDate = new Date();

  onSelect(event){
    this.selectedDate= event;
   this.selectedFormattedDate=this.datePipe.transform(this.selectedDate,'MMMM-dd-yyyy');
    console.log(this.selectedFormattedDate);
    this.selectedMonthInStringDate=this.datePipe.transform(this.selectedDate,'MMMM');
    console.log(this.selectedMonthInStringDate);
    this.selectedYearDate=this.datePipe.transform(this.selectedDate,'yyyy');


    this.selectedMonthInDigitsDate=this.datePipe.transform(this.selectedDate,'MM');
    console.log(this.selectedMonthInDigitsDate);
  console.log(this.selectedYearDate);
  }





  changeorderclick(){

    this.changedselectedFormattedDate.emit(this.selectedFormattedDate);
    this.changedselectedMonthInDigitsDate.emit(this.selectedMonthInDigitsDate);
    this.changedselectedMonthInStringDate.emit(this.selectedMonthInStringDate);
    this.changedselectedYearDate.emit(this.selectedYearDate);
    this.activeModal.close();

  }


}
