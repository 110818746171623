import { Component, OnInit, Input } from '@angular/core';
import { ToolbarHelpers } from './toolbar.helpers';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'cdk-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

	username:any;
	
  @Input() sidenav;
	@Input() sidebar;
	@Input() drawer;
	@Input() matDrawerShow;
  
	searchOpen: boolean = false;
    toolbarHelpers = ToolbarHelpers;
	constructor(
		public router: Router
		
		) { 
		
		 }

  	ngOnInit() {
		this.username = JSON.parse(localStorage.getItem('loginuserName'));
  	}

	  updatepwdclicked(){
		this.router.navigate(['pages/updatepwd']);

		// this.router.navigate(['updatepwd']);
	  }

	  logoutclicked(){
		localStorage.clear();
		localStorage.setItem('LoggedInStatus', JSON.stringify(false));
		this.router.navigate(['login']);
	}
	 
}
