import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs/Rx';
import { DataService } from './data.service';
import { CacheService } from './cache.service';
function _window():any{
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
get nativeWindow() : any {
return _window();
}
  adminLoggedIn: any;
  userLoggedIn() {
    throw new Error("Method not implemented.");
  }
  constructor(private configService: ConfigService,
    public dataService: DataService,
    public cacheService: CacheService, ) { }
}