import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs/Rx';
import { DataService } from './data.service';
import { CacheService } from './cache.service';
import * as _ from 'lodash';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class GetListService {

  constructor(private configService: ConfigService,
    public dataService: DataService,
    public cacheService: CacheService,
    private _http: Http) { }




/////////////////////////////


superadminLogin(obj) {
  return this.dataService.postdataToken(this.configService.SUPER_ADMIN_LOGIN, obj)
    .flatMap(rData => {

      console.log('Logged in successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in SuperAdmin login -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}



editsuperadminLogin(obj) {
  return this.dataService.putdataHeaders(this.configService.EDIT_SUPER_ADMIN, obj)
    .flatMap(rData => {

      console.log('Edit Admin successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in Edit SuperAdmin -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}




GetuserInfo(obj) {
  return this.dataService.getDataparams(this.configService.GET_USER_DETAILS, obj)
    .flatMap(rData => {

      console.log('GET USER DETAILS  successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('error in GET USER DETAILS name -------->')
      console.log(error);
      return Observable.throw(error)
    }
    )
}


addMinimumOrdervalue(obj) {
  return this.dataService.postdataToken(this.configService.MINIMUM_ORDER_VALUE_QUANTITY, obj)
    .flatMap(rData => {

      console.log('Create minimum value successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in creating minimum value -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


getAddressesList(obj) {
  return this.dataService.getDataparams(this.configService.ADDRESS_LIST, obj)
    .flatMap(rData => {
      console.log('--Addresses List----------->')
      console.log(rData)
      if (this.cacheService.cachedAddressesList) {
        if (rData.data) {
          this.cacheService.cachedAddressesList = _.concat(this.cacheService.cachedAddressesList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedAddressesList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}





addTaxpercentage(obj) {
  return this.dataService.postdataToken(this.configService.ADD_TAX_PERCENTAGE, obj)
    .flatMap(rData => {

      console.log('Add Taxpercentage value successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in adding tax percentage value -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


addIndustry(obj) {
  return this.dataService.postdataToken(this.configService.ADD_INDUSTRY, obj)
    .flatMap(rData => {

      console.log('Add Industry successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch( 
    error => {

      console.log('error in adding Industry -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}




getIndustriesList(obj) {
  return this.dataService.getDataparams(this.configService.GET_INDUSTRIES_LIST, obj)
    .flatMap(rData => {
      console.log('--Industries List Successful----------->')
      console.log(rData)
      if (this.cacheService.cachedIndustrieslist) {
        if (rData.data) {
          this.cacheService.cachedIndustrieslist = _.concat(this.cacheService.cachedIndustrieslist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedIndustrieslist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}



deleteIndustry(obj) {
  return this.dataService.deleteDataWithTokens(this.configService.DELETE_INDUSTRY, obj)
    .flatMap(rData => {
      // this.cacheService.userData = rData;
      console.log('Deleted industry successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in deleting industry name-------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

getSubAdminList(obj) {
  return this.dataService.getDataparams(this.configService.SUBADMIN_LIST, obj)
    .flatMap(rData => {
      console.log('--subadmin List----------->')
      console.log(rData)
      if (this.cacheService.cachedSubAdmin) {
        if (rData.data) {
          this.cacheService.cachedSubAdmin = _.concat(this.cacheService.cachedSubAdmin, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedSubAdmin = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

createSubAdmin(storyData: any): any {
  return this.dataService.postData(this.configService.CREATE_SUBADMIN, storyData)
      .flatMap(rData => {
          console.log('Sub Admin created successful ------->')
          console.log(rData)
          return Observable.of(rData)
      }).catch(
          error => {
              console.log('error in Sub Admin created ------->')
              console.log(error);
              return Observable.throw(error)
          }
      );
}


getAccessList(obj) {
  return this.dataService.getDataparams(this.configService.ACCESS_LIST, obj)
    .flatMap(rData => {
      console.log('--access List----------->')
      console.log(rData)
      if (this.cacheService.cachedAccessList) {
        if (rData.data) {
          this.cacheService.cachedAccessList = _.concat(this.cacheService.cachedAccessList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedAccessList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}


getProductsList(obj) {
  return this.dataService.getDataparams(this.configService.GET_PRODUCT_LIST, obj)
    .flatMap(rData => {
      console.log('--Products List Successful----------->')
      console.log(rData)
      if (this.cacheService.cachedProductslist) {
        if (rData.data) {
          this.cacheService.cachedProductslist = _.concat(this.cacheService.cachedProductslist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedProductslist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

addProduct(obj) {
  return this.dataService.postdataToken(this.configService.ADD_PRODUCT, obj)
    .flatMap(rData => {

      console.log('Add Product successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch( 
    error => {

      console.log('error in adding Product -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

addContactUsData(obj) {
  return this.dataService.postdataToken(this.configService.CONTACTUS, obj)
    .flatMap(rData => {

      console.log('Added Contact successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in adding contact  details-------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


deleteProduct(obj) {
  return this.dataService.deleteDataWithTokens(this.configService.DELETE_PRODUCT, obj)
    .flatMap(rData => {
      // this.cacheService.userData = rData;
      console.log('Deleted product successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in deleting product name-------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}



getInactiveList(obj) {
  return this.dataService.getDataparams(this.configService.INACTIVE_LIST, obj)
    .flatMap(rData => {
      console.log('--inactive List----------->')
      console.log(rData)
      if (this.cacheService.cachedInactiveList) {
        if (rData.data) {
          this.cacheService.cachedInactiveList = _.concat(this.cacheService.cachedInactiveList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedInactiveList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}


getPriorityList(obj) {
  return this.dataService.getDataparams(this.configService.PRIORITY_LIST, obj)
    .flatMap(rData => {
      console.log('--priority List----------->')
      console.log(rData)
      if (this.cacheService.cachedPriorityList) {
        if (rData.data) {
          this.cacheService.cachedPriorityList = _.concat(this.cacheService.cachedPriorityList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedPriorityList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getNormalList(obj) {
  return this.dataService.getDataparams(this.configService.PRIORITY_LIST, obj)
    .flatMap(rData => {
      console.log('--normal List----------->')
      console.log(rData)
      if (this.cacheService.cachedNormalList) {
        if (rData.data) {
          this.cacheService.cachedNormalList = _.concat(this.cacheService.cachedNormalList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedNormalList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getBowserSearchList(obj) {
  return this.dataService.getDataparams(this.configService.BOWSER_SEARCH, obj)
    .flatMap(rData => {
      console.log('--bowser search List----------->')
      console.log(rData)
      if (this.cacheService.cachedBowserSearchList) {
        if (rData.data) {
          this.cacheService.cachedBowserSearchList = _.concat(this.cacheService.cachedBowserSearchList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedBowserSearchList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getAllCustomersList(obj) {
  return this.dataService.getDataparams(this.configService.ALL_CUSTOMERS_LIST, obj)
    .flatMap(rData => {
      console.log('--customers  List----------->')
      console.log(rData)
      if (this.cacheService.cachedallcustomersList) {
        if (rData.data) {
          this.cacheService.cachedallcustomersList = _.concat(this.cacheService.cachedallcustomersList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedallcustomersList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getCreditsEnabledList(obj) {
  return this.dataService.getDataparams(this.configService.CREDIT_ENABLED_LIST, obj)
    .flatMap(rData => {
      console.log('--credit enabled customer  List----------->')
      console.log(rData)
      if (this.cacheService.cachedcreditsEnabledList) {
        if (rData.data) {
          this.cacheService.cachedcreditsEnabledList = _.concat(this.cacheService.cachedcreditsEnabledList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedcreditsEnabledList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getBowserDetails(obj) {
  return this.dataService.getDataparams(this.configService.GET_BOWSER_DETAILS, obj)
    .flatMap(rData => {
      console.log('--credit disabled customer  List----------->')
      console.log(rData)
      if (this.cacheService.cachedBowserdetailsList) {
        if (rData.data) {
          this.cacheService.cachedBowserdetailsList = _.concat(this.cacheService.cachedBowserdetailsList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedBowserdetailsList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

editBowserOwner(obj) {
  return this.dataService.putdataHeaders(this.configService.EDIT_BOWSER, obj)
    .flatMap(rData => {

      console.log('Edit Bowser successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in Edit Bowser -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


editBowserVehicle(obj) {
  return this.dataService.putdataHeaders(this.configService.EDIT_BOWSER_VEHICLE, obj)
    .flatMap(rData => {

      console.log('Edit Bowser Vehicle successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in Edit Bowser Vehicle-------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


getCreditsDisabledList(obj) {
  return this.dataService.getDataparams(this.configService.CREDIT_DISABLED_LIST, obj)
    .flatMap(rData => {
      console.log('--credit disabled customer  List----------->')
      console.log(rData)
      if (this.cacheService.cachedcreditsDisabledList) {
        if (rData.data) {
          this.cacheService.cachedcreditsDisabledList = _.concat(this.cacheService.cachedcreditsDisabledList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedcreditsDisabledList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getUserSearchList(obj) {
  return this.dataService.getDataparams(this.configService.SEARCH_USER, obj)
    .flatMap(rData => {
      console.log('--user search  List----------->')
      console.log(rData)
      if (this.cacheService.cacheduserSearchList) {
        if (rData.data) {
          this.cacheService.cacheduserSearchList = _.concat(this.cacheService.cacheduserSearchList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cacheduserSearchList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

postEnableAndDisable(obj) {
  return this.dataService.putDataWithParams(this.configService.ENABLE_DISABLE, obj)
      .flatMap(rData => {
          console.log('-- enable and disable LIST----------->')
          console.log(rData)
          return Observable.of(rData)
      }).catch(
          error => {
              console.log(error);
              return Observable.throw(error)
          }
      )
}

getTownList(obj) {
  return this.dataService.getDataparams(this.configService.GET_TOWNS_LIST, obj)
    .flatMap(rData => {
      console.log('--Towns List Successful----------->')
      console.log(rData)
      if (this.cacheService.cachedTownslist) {
        if (rData.data) {
          this.cacheService.cachedTownslist = _.concat(this.cacheService.cachedTownslist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedTownslist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getTownListbymandal(obj) {
  return this.dataService.getDataparams(this.configService.TOWN_LIST, obj)
    .flatMap(rData => {
      console.log('--Towns List Successful----------->')
      console.log(rData)
      if (this.cacheService.cachedTownsbymandallist) {
        if (rData.data) {
          this.cacheService.cachedTownsbymandallist = _.concat(this.cacheService.cachedTownsbymandallist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedTownsbymandallist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getDistrictList(obj) {
  return this.dataService.getDataparams(this.configService.GET_DISTRICT_LIST, obj)
    .flatMap(rData => {
      console.log('--district List Successful----------->')
      console.log(rData)
      if (this.cacheService.cachedDistrictlist) {
        if (rData.data) {
          this.cacheService.cachedDistrictlist = _.concat(this.cacheService.cachedDistrictlist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedDistrictlist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getMandalList(obj) {
  return this.dataService.getDataparams(this.configService.GET_MANDAL_LIST, obj)
    .flatMap(rData => {
      console.log('--Mandal List Successful----------->')
      console.log(rData)
      if (this.cacheService.cachedMandallist) {
        if (rData.data) {
          this.cacheService.cachedMandallist = _.concat(this.cacheService.cachedMandallist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedMandallist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getVillageList(obj) {
  return this.dataService.getDataparams(this.configService.GET_VILLAGE_LIST, obj)
    .flatMap(rData => {
      console.log('--Towns List Successful----------->')
      console.log(rData)
      if (this.cacheService.cachedVillagelist) {
        if (rData.data) {
          this.cacheService.cachedVillagelist = _.concat(this.cacheService.cachedVillagelist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedVillagelist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

createbowser(obj) {
  return this.dataService.postdataToken(this.configService.CREATE_BOWSER, obj)
    .flatMap(rData => {

      console.log('Created bowser successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('error in creating bowser -------->')
      console.log(error);
      return Observable.throw(error)
    }
    )
}

Deletebowser(obj) {
  return this.dataService.deleteDataWithTokens(this.configService.DELETE_BOWSER, obj)
    .flatMap(rData => {
      // this.cacheService.userData = rData;
      console.log('Delete Bowser successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in deleting Bowser -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

addTimeSlotsvalue(obj) {
  return this.dataService.postdataToken(this.configService.ADDSLOTSTIME, obj)
    .flatMap(rData => {

      console.log('Add time slots value successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in creating time slots  -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

addBookingAmountvalue(obj) {
  return this.dataService.postdataToken(this.configService.ADDBOOKINGAMOUNT, obj)
    .flatMap(rData => {

      console.log('Add Booking value successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in adding booking amount  -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


addCreditLimitvalue(obj) {
  return this.dataService.postdataToken(this.configService.ADDCREDITLIMIT, obj)
    .flatMap(rData => {

      console.log('Add CREDIT LIMIT value successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in adding credit limit  -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


/// feedback list ///

getAllFeedbackList(obj) {
  return this.dataService.getDataparams(this.configService.FEEDBACK_LIST, obj)
    .flatMap(rData => {
      console.log('--Feedback  List----------->')
      console.log(rData)
      if (this.cacheService.cachedAllFeedbackList) {
        if (rData.data) {
          this.cacheService.cachedAllFeedbackList = _.concat(this.cacheService.cachedAllFeedbackList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedAllFeedbackList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}
getBowserAllFeedbackList(obj) {
  return this.dataService.getDataparams(this.configService.BOWSER_FEEDBACK_LIST, obj)
    .flatMap(rData => {
      console.log('--Bowser Feedback  List----------->')
      console.log(rData)
      if (this.cacheService.cachedBowserAllFeedbackList) {
        if (rData.data) {
          this.cacheService.cachedBowserAllFeedbackList = _.concat(this.cacheService.cachedBowserAllFeedbackList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedBowserAllFeedbackList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getTodaysFeedbackList(obj) {
  return this.dataService.getDataparams(this.configService.FEEDBACK_LIST_DAYS, obj)
    .flatMap(rData => {
      console.log('--Feedback  List for Today,Betwwen dates----------->')
   
      console.log(rData)
      if (this.cacheService.cachedTodayFeedbackList) {
        if (rData.data) {
          this.cacheService.cachedTodayFeedbackList = _.concat(this.cacheService.cachedTodayFeedbackList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedTodayFeedbackList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getBowserTodaysFeedbackList(obj) {
  return this.dataService.getDataparams(this.configService.FEEDBACK_LIST_DAYS, obj)
    .flatMap(rData => {
      console.log('-- Bowser Feedback  List for Today,Between dates----------->')
      console.log(rData)
      if (this.cacheService.cachedBowserTodayFeedbackList) {
        if (rData.data) {
          this.cacheService.cachedBowserTodayFeedbackList = _.concat(this.cacheService.cachedBowserTodayFeedbackList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedBowserTodayFeedbackList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getThirtyDaysPriorFeedbackList(obj) {
  return this.dataService.getDataparams(this.configService.FEEDBACK_LIST_DAYS, obj)
    .flatMap(rData => {
      console.log('-- Feedback  List  for 30 days dates----------->')
      console.log(rData)
      if (this.cacheService.cachedThirtyDaysFeedbackList) {
        if (rData.data) {
          this.cacheService.cachedThirtyDaysFeedbackList = _.concat(this.cacheService.cachedThirtyDaysFeedbackList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedThirtyDaysFeedbackList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}


getBowserThirtyDaysPriorFeedbackList(obj) {
  return this.dataService.getDataparams(this.configService.FEEDBACK_LIST_DAYS, obj)
    .flatMap(rData => {
      console.log('--Bowser Feedback  List  for 30 days dates----------->')
      console.log(rData)
      if (this.cacheService.cachedBowserThirtyDaysFeedbackList) {
        if (rData.data) {
          this.cacheService.cachedBowserThirtyDaysFeedbackList = _.concat(this.cacheService.cachedBowserThirtyDaysFeedbackList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedBowserThirtyDaysFeedbackList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

addHomePageBanners(obj) {
  return this.dataService.postdataToken(this.configService.ADD_HOMEPAGE_BANNER, obj)
    .flatMap(rData => {

      console.log('Added HomePage Banner successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in adding homepage banner  -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

getHomeBannerList() {
  return this.dataService.getDataWithHeaders(this.configService.HOMEPAGE_BANNER_LIST)
  .flatMap(rData => {

    console.log("HomePage Banner successful--------------->")
    console.log(rData)
    return Observable.of(rData)
  }).catch(
  error => {

    console.log('error in loading homepage banner  -------->')

    console.log(error);
    return Observable.throw(error)
  }
    );
}

Deletesubadmin(obj) {
  return this.dataService.deleteDataWithTokens(this.configService.DELETE_SUBADMIN, obj)
    .flatMap(rData => {
      // this.cacheService.userData = rData;
      console.log('Delete subadmin successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in deleting subadmin -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

editSubadmin(obj) {
  return this.dataService.putdataHeaders(this.configService.EDIT_SUBADMIN, obj)
    .flatMap(rData => {
      console.log('updated subadmin successfully--------------->')
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('Error in editing subadmin -------->')
      return Observable.throw(error)
    }
    )
}

/////////

getAllTimeSlotsList(obj) {
  return this.dataService.getDataparams(this.configService.TIME_SLOT_LIST, obj)
    .flatMap(rData => {
      console.log('--TimeSlot  List----------->')
      console.log(rData)
      if (this.cacheService.cachedTimeSlotsList) {
        if (rData.data) {
          this.cacheService.cachedTimeSlotsList = _.concat(this.cacheService.cachedTimeSlotsList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedTimeSlotsList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

deleteTimeSlot(obj) {
  return this.dataService.deleteDataWithTokens(this.configService.DELETE_TIMESLOT, obj)
    .flatMap(rData => {
      // this.cacheService.userData = rData;
      console.log('Deleted TimeSlot successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in deleting timeslot-------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


getTownsListforCSVList(obj) {
  return this.dataService.getDataparams(this.configService.TOWN_LIST_CSV, obj)
    .flatMap(rData => {
      console.log('--Towns List----------->')
      console.log(rData)
      if (this.cacheService.cachedCsvData) {
        if (rData.data) {
          this.cacheService.cachedCsvData = _.concat(this.cacheService.cachedCsvData, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedCsvData = rData.data;
        }
      }
      return Observable.of(rData)
    });
}


salesDownlad(obj) {
  return this.dataService.putDataWithParams(this.configService.SALES_DOWNLOAD, obj)
      .flatMap(rData => {
          console.log('-- Sales download ----------->')
          console.log(rData)
          // if (this.cacheService.cachedsalesReportData) {
          //   if (rData.data) {
          //     this.cacheService.cachedsalesReportData = _.concat(this.cacheService.cachedsalesReportData, rData.data)
          //   }
          // } else {
          //   if (rData.data) {
          //     this.cacheService.cachedsalesReportData = rData.data;
          //   }
          // }
          return Observable.of(rData)
      }).catch(
          error => {
              console.log(error);
              return Observable.throw(error)
          }
      )
}

settlementdownload(obj) {
  return this.dataService.putDataWithParams(this.configService.SETTLEMENT_DOWNLOAD, obj)
      .flatMap(rData => {
          console.log('-- Settlement download----------->')
          console.log(rData)
          if (this.cacheService.cachedsettlementReportData) {
            if (rData.data) {
              this.cacheService.cachedsettlementReportData = _.concat(this.cacheService.cachedsettlementReportData, rData.data)
            }
          } else {
            if (rData.data) {
              this.cacheService.cachedsettlementReportData = rData.data;
            }
          }
          return Observable.of(rData)
      }).catch(
          error => {
              console.log(error);
              return Observable.throw(error)
          }
      )
}

creditsDownload(obj) {
  return this.dataService.putDataWithParams(this.configService.CREDITS_DOWNLOAD, obj)
      .flatMap(rData => {
          console.log('-- credits download----------->')
          console.log(rData)
          if (this.cacheService.cachedcreditReportData) {
            if (rData.data) {
              this.cacheService.cachedcreditReportData = _.concat(this.cacheService.cachedcreditReportData, rData.data)
            }
          } else {
            if (rData.data) {
              this.cacheService.cachedcreditReportData = rData.data;
            }
          }
          return Observable.of(rData)
      }).catch(
          error => {
              console.log(error);
              return Observable.throw(error)
          }
      )
}

//  get minimum value data

getMinimumValue() {
  return this.dataService.getDataWithHeaders(this.configService.GET_MINIMUM_ORDER)
  .flatMap(rData => {
    console.log("Minium order value quantity sucess--------------->")
    console.log(rData)
    return Observable.of(rData)
  }).catch(
  error => {
    console.log('error in loading minimum order value  -------->')
    console.log(error);
    return Observable.throw(error)
  });
}

//  get tax percentage data
getTaxPercentageValue() {
  return this.dataService.getDataWithHeaders(this.configService.GET_TAX_PERCENTAGE)
  .flatMap(rData => {
    console.log("Get Tax Percentage sucess--------------->")
    console.log(rData)
    return Observable.of(rData)
  }).catch(
  error => {
    console.log('error in loading tax percentage  -------->')
    console.log(error);
    return Observable.throw(error)
  });
}

//  get contact info data
getContactInfo() {
  return this.dataService.getDataWithHeaders(this.configService.GET_CONTACT_INFO)
  .flatMap(rData => {
    console.log("Contact Info sucess--------------->")
    console.log(rData)
    return Observable.of(rData)
  }).catch(
  error => {
    console.log('error in loading contact Info  -------->')
    console.log(error);
    return Observable.throw(error)
  });
}

//  get Booking amount data
getBookingAmount() {
  return this.dataService.getDataWithHeaders(this.configService.GET_BOOKINGAMOUNT)
  .flatMap(rData => {
    console.log("Booking Amount sucess--------------->")
    console.log(rData)
    return Observable.of(rData)
  }).catch(
  error => {
    console.log('error in loading booking amount data  -------->')
    console.log(error);
    return Observable.throw(error)
  });
}


//  get Credit Limit data
getCredditLimitData() {
  return this.dataService.getDataWithHeaders(this.configService.GET_CREDIT_LIMIT)
  .flatMap(rData => {
    console.log("Credit Limit  sucess--------------->")
    console.log(rData)
    return Observable.of(rData)
  }).catch(
  error => {
    console.log('error in loading credit limit data  -------->')
    console.log(error);
    return Observable.throw(error)
  });
}

/////// GET BOWSERS LIST/////////


getAllBowsersList(obj) {
  return this.dataService.getDataparams(this.configService.BOWSERSLIST, obj)
    .flatMap(rData => {
      console.log('--Bowsers List----------->')
      console.log(rData)
      if (this.cacheService.cachedBowsersList) {
        if (rData.data) {
          this.cacheService.cachedBowsersList = _.concat(this.cacheService.cachedBowsersList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedBowsersList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}





assignordertobowser(obj) {
  return this.dataService.putdataHeaders(this.configService.ASSIGN_ORDER_TO_BOWSER, obj)
    .flatMap(rData => {

      console.log('Assign order to bowser successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in assigning order to bowser -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

/////////

getAllInProgressOrderList(obj) {
  return this.dataService.getDataparams(this.configService.GET_ALL_ASSIGNED_ORDERSLIST, obj)
    .flatMap(rData => {
      console.log('--In Progress order List Sucess ----------->')
      console.log(rData)
      if (this.cacheService.cachedInProgressOrderList) {
        if (rData.data) {
          this.cacheService.cachedInProgressOrderList = _.concat(this.cacheService.cachedInProgressOrderList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedInProgressOrderList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getAllCompletedOrderList(obj) {
  return this.dataService.getDataparams(this.configService.GET_ALL_COMPLETED_ORDERLIST, obj)
    .flatMap(rData => {
      console.log('-- Completed ordersList Sucess----------->')
      console.log(rData)
      if (this.cacheService.cachedCompletedOrderList) {
        if (rData.data) {
          this.cacheService.cachedCompletedOrderList = _.concat(this.cacheService.cachedCompletedOrderList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedCompletedOrderList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

//////// Pending Order dates and count ///////

getAllPendingOrderDatesnCount(obj) {
  return this.dataService.getDataparams(this.configService.PENDING_ORDER_DATES_COUNT, obj)
    .flatMap(rData => {
      console.log('-- Pending ordersDates Sucess----------->')
      console.log(rData)
      if (this.cacheService.cachedPendingOrdersDatesList) {
        if (rData.data) {
          this.cacheService.cachedPendingOrdersDatesList = _.concat(this.cacheService.cachedPendingOrdersDatesList, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedPendingOrdersDatesList = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

GetVehiclesList(obj) {
  return this.dataService.getDataparams(this.configService.VEHICLES_LIST, obj)
    .flatMap(rData => {

      console.log('GET VEHILCES DETAILS  successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('error in GET VEHILCES DETAILS  -------->')
      console.log(error);
      return Observable.throw(error)
    }
    )
}




checkmobilenumber(obj) {
  return this.dataService.getDataparams(this.configService.CHECK_MOBILE_NUMBER, obj)
    .flatMap(rData => {

      console.log('Check mobile number successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('error in Check mobile number  -------->')
      console.log(error);
      return Observable.throw(error)
    }
    )
}


//user web//
getBannersList(obj) {
  return this.dataService.getDataparams(this.configService.BANNERS_LIST, obj)
    .flatMap(rData => {
      console.log('--banners List----------->')
      console.log(rData)
      if (this.cacheService.cachedBanners) {
        if (rData.data) {
          this.cacheService.cachedBanners = _.concat(this.cacheService.cachedBanners, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedBanners = rData.data;
        }
      }
      return Observable.of(rData)
    });
}



addFeedback(obj) {
  return this.dataService.postdataToken(this.configService.ADD_FEEDBACK, obj)
    .flatMap(rData => {

      console.log('Add FEEDBACK successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch( 
    error => {

      console.log('error in adding feedback -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


getProfileDetailsList(obj) {
  return this.dataService.getDataparams(this.configService.PROFILE_DETAILS, obj)
    .flatMap(rData => {
      console.log(rData)
      if (this.cacheService.cachedProfileDetailslist) {
        if (rData.data) {
          this.cacheService.cachedProfileDetailslist = _.concat(this.cacheService.cachedProfileDetailslist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedProfileDetailslist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

editProfile(obj) {
  return this.dataService.postdataToken(this.configService.EDIT_PROFILE, obj)
    .flatMap(rData => {

      console.log('update profile successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch( 
    error => {

      console.log('error in updating profile -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


getAddressList(obj) {
  return this.dataService.getDataparams(this.configService.ADDRESS_LIST, obj)
    .flatMap(rData => {
      console.log(rData)
      if (this.cacheService.cachedaddresslistdetails) {
        if (rData.data) {
          this.cacheService.cachedaddresslistdetails = _.concat(this.cacheService.cachedaddresslistdetails, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedaddresslistdetails = rData.data;
        }
      }
      return Observable.of(rData)
    });
}


addNewAddress(storyData: any): any {
  return this.dataService.postData(this.configService.ADD_ADDRESS, storyData)
      .flatMap(rData => {
          console.log('address added successful ------->')
          console.log(rData)
          return Observable.of(rData)
      }).catch(
          error => {
              console.log('error in adding address ------->')
              console.log(error);
              return Observable.throw(error)
          }
      );
}


DeleteAddress(obj) {
  return this.dataService.deleteDataWithTokens(this.configService.DELETE_ADDRESSES, obj)
    .flatMap(rData => {
      // this.cacheService.userData = rData;
      console.log('Delete address successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in deleting address -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}

DeleteOrder(obj) {
  return this.dataService.putDataWithParams(this.configService.CANCEL_ORDER, obj)
    .flatMap(rData => {
      console.log('Delete order successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in deleting order -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}




  downloadInvoice(obj) {
    return this.dataService.putDataWithParams(this.configService.ORDER_INVOICE, obj)
        .flatMap(rData => {
            console.log('-- Invoice download----------->')
            console.log(rData)
           
            return Observable.of(rData)
        }).catch(
            error => {
                console.log(error);
                return Observable.throw(error)
            }
        )
  }


  modifyOrderDate(obj) {
    return this.dataService.putdataHeaders(this.configService.CHANGE_ORDER_DATE, obj)
      .flatMap(rData => {
  
        console.log('change order successfully--------------->')
        console.log(rData)
        return Observable.of(rData)
      }).catch(
      error => {
  
        console.log('error in changing order date -------->')
  
        console.log(error);
        return Observable.throw(error)
      }
      )
  }


  getserviceotp(obj) {
    return this.dataService.putDataWithParams(this.configService.SEND_SERVICE_OTP, obj)
      .flatMap(rData => {
        console.log('otp sent successfully--------------->')
        return Observable.of(rData)
      }).catch(
      error => {
        console.log('Error in sending otp -------->')
        return Observable.throw(error)
      }
      )
  }
  
  
  monthList(obj) {
    return this.dataService.getDataparams(this.configService.MONTHS_LISTING, obj)
      .flatMap(rData => {
  
        console.log('months list success--------------->')
        console.log(rData)
        return Observable.of(rData)
      }).catch(
      error => {
        console.log('error in getting listing months -------->')
        console.log(error);
        return Observable.throw(error)
      }
      )
  }
//////////////////////thabitha code///////////////////////////////


userlogin(userData: any): any {
  return this.dataService.postData(this.configService.USER_LOGIN, userData)
      .flatMap(rData => {
          console.log('User Login successful ------->')
          console.log(rData)
          return Observable.of(rData)
      }).catch(
          error => {
              console.log('error in user login ------->')
              console.log(error);
              return Observable.throw(error)
          }
      );
}

getOrdersList(obj) {
  return this.dataService.getDataparams(this.configService.ORDERS_LIST, obj)
    .flatMap(rData => {
      console.log('--orders List----------->')
      console.log(rData)
      if (this.cacheService.cachedorderlist) {
        if (rData.data) {
          this.cacheService.cachedorderlist = _.concat(this.cacheService.cachedorderlist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedorderlist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}


getOrderDateList(obj) {
  return this.dataService.getDataparams(this.configService.ORDERS_DATES, obj)
    .flatMap(rData => {
      console.log('--orders dates list----------->')
      console.log(rData)
      if (this.cacheService.cachedorderdateslist) {
        if (rData.data) {
          this.cacheService.cachedorderdateslist = _.concat(this.cacheService.cachedorderdateslist, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedorderdateslist = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

getOrderDetails(obj) {
  return this.dataService.getDataparams(this.configService.GET_ORDER_DETAILS, obj)
    .flatMap(rData => {
      console.log('--orders details----------->')
      console.log(rData)
      if (this.cacheService.cachedorderdetails) {
        if (rData.data) {
          this.cacheService.cachedorderdetails = _.concat(this.cacheService.cachedorderdetails, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedorderdetails = rData.data;
        }
      }
      return Observable.of(rData)
    });
}


getorderandvolume(obj) {
  return this.dataService.getDataparams(this.configService.ORDER_TOTAL_GRAPH, obj)
    .flatMap(rData => {
      console.log('--orders details----------->')
      console.log(rData)
      if (this.cacheService.cachedordervolume) {
        if (rData.data) {
          this.cacheService.cachedordervolume = _.concat(this.cacheService.cachedordervolume, rData.data)
        }
      } else {
        if (rData.data) {
          this.cacheService.cachedordervolume = rData.data;
        }
      }
      return Observable.of(rData)
    });
}

paybalance(obj) {
  return this.dataService.putDataWithParams(this.configService.BALANCE_AMOUNT, obj)
      .flatMap(rData => {
          console.log('-- paid successfully----------->')
          console.log(rData)
          return Observable.of(rData)
      }).catch(
          error => {
              console.log(error);
              return Observable.throw(error)
          }
      )
}












userregistration(userData: any): any {
  return this.dataService.postData(this.configService.REGISTRATION, userData)
      .flatMap(rData => {
          console.log('User Registration successful ------->')
          console.log(rData)
          return Observable.of(rData)
      }).catch(
          error => {
              console.log('error in user Registration ------->')
              console.log(error);
              return Observable.throw(error)
          }
      );
}


setpassword(obj) {
  return this.dataService.putdataHeaders(this.configService.SET_PASSWORD, obj)
    .flatMap(rData => {

      console.log('Set password successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {

      console.log('error in set passwordr -------->')

      console.log(error);
      return Observable.throw(error)
    }
    )
}


      //// get dezel current price /////

getdezalCurrentprice(obj) {
  return this.dataService.getDataparams(this.configService.GETPRICE_DELIVERYCHARGES, obj)
    .flatMap(rData => {

      console.log('current dezal price details successful--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('error in getting dezal current price -------->')
      console.log(error);
      return Observable.throw(error)
    }
    )
}

/////// add order api /////

addOrder(orderData: any): any {
  return this.dataService.postData(this.configService.ADD_ORDER, orderData)
      .flatMap(rData => {
          console.log('Add Order successful ------->')
          console.log(rData)
          return Observable.of(rData)
      }).catch(
          error => {
              console.log('error in user adding order ------->')
              console.log(error);
              return Observable.throw(error)
          }
      );
}






GETYearlygraphDetails(obj) {
  return this.dataService.getDataparams(this.configService.YEARLY_GRAPH_DETAILS, obj)
    .flatMap(rData => {

      console.log('GET YEAR DETAILS  successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('error in GET YEAR DETAILS  -------->')
      console.log(error);
      return Observable.throw(error)
    }
    )
}


getquarterlygraphDetails(obj) {
  return this.dataService.getDataparams(this.configService.QUARTERLY_GRAPH_DETAILS, obj)
    .flatMap(rData => {

      console.log('GET QUARTERLY DETAILS  successfully--------------->')
      console.log(rData)
      return Observable.of(rData)
    }).catch(
    error => {
      console.log('error in GET QUARTERLY DETAILS  -------->')
      console.log(error);
      return Observable.throw(error)
    }
    )
}


}