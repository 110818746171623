import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CreatesubadminComponent } from '../createsubadmin/createsubadmin.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from '../../service/cache.service';
import { GetListService } from '../../service/get-list.service';
import { EditSubadminComponent } from '../edit-subadmin/edit-subadmin.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-subadmins',
  templateUrl: './subadmins.component.html',
  styleUrls: ['./subadmins.component.scss']
})
export class SubadminsComponent implements OnInit {
  modalRef: BsModalRef;
  selectedCheckboxType: any;
  pageNO = 0;
  subadminList: any;
  acceslistdata: any;
  clickeduserNameToDelete: any;
  password:any;
  name: any;
  username: any;
  acceslist: any;
  subadminlistitems: any;
  anames: any;
  subadminEmptyList=false;
  constructor(private modalservice: BsModalService,
    private modalService: NgbModal, private serverService: GetListService,
    public cacheService: CacheService,public router: Router) {
    this.cacheService.cachedSubAdmin = []
  }
  ngOnInit() {

    if(!JSON.parse(localStorage.getItem('LoggedInStatus'))){
            this.router.navigate(['login']);
          }


    this.getSubAdminLists();
  }
  postDialogue($event) {
    const modalRef = this.modalService.open(CreatesubadminComponent);
    modalRef.componentInstance.creatingsubadminoutput.subscribe((emmitedValue) => {
      console.log("InsidegettingOtp");
      this.subadminList =[];
      this.cacheService.cachedSubAdmin=[];
      this.pageNO=0;
      this.getSubAdminLists();
    });
    modalRef.componentInstance.imageChangedEvent = $event;
    modalRef.componentInstance.selectedtype = this.selectedCheckboxType
  }
  openSubadminEditDialog($event,objs): void{
    this.name=objs.userName;
    this.username=objs.adminID;
    this.password=objs.passWord;
    this.acceslist=objs.accessNames;
    const modalRef = this.modalService.open(EditSubadminComponent
    );
    modalRef.componentInstance.editingsubadminoutput.subscribe((emmitedValue) => {
      this.subadminList =[];
      this.cacheService.cachedSubAdmin=[];
      this.pageNO=0;
      this.getSubAdminLists();
    });
    modalRef.componentInstance.inputDataInModalComponent  =objs;
    modalRef.componentInstance.imageChangedEvent = $event;
    modalRef.componentInstance.selectedtype = this.selectedCheckboxType;
    // this.router.navigate(['/editsubadmin'], {queryParams: {name:this.name,
    //    username:this.username,password:this.password,accesslistnames:this.acceslist} });
  }
  onScrollDown(event) {
    this.getSubAdminLists();
  }
  getSubAdminLists() {
    var Obj = {
      "pageNO": this.pageNO,
    }
    this.serverService.getSubAdminList(Obj).subscribe(
      rdata => {
          if (rdata) {
            if(rdata.data === undefined || rdata.data.length == 0){
              this.subadminEmptyList=true;
            }else{
              this.subadminEmptyList=false;
  
            }
            console.log("empty data check"+rdata.data)
          this.subadminList =  this.cacheService.cachedSubAdmin;
          this.pageNO = this.pageNO + 1;
//           for(let list of this.subadminList){
//              this.subadminlistitems=list;
//  console.log(this.subadminlistitems);
//            }
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }
deletesubadminclicked(name: string,objs){
  if(confirm("Are you sure to delete subadmin? ")) {
    this.clickeduserNameToDelete = objs.userName
    this.deletesubadmin();
  }
}
  deletesubadmin() {
    var Obj = {
      "userName": this.clickeduserNameToDelete
    }
    this.serverService.Deletesubadmin(Obj).subscribe(
      rdata => {
        if (rdata) {
          alert("Deleted subadmin Suceessfully!");
          // window.location.reload();
          this.cacheService.cachedSubAdmin=[];
          this.pageNO=0;
          this.subadminList=[];
         this.getSubAdminLists();
        }
        else {
          alert(rdata.message)
          // this.cacheService.displaySpinner = false;
        }
      },
      error => {
        // this.cacheService.displaySpinner = false;
      }
    )
  }
}