import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubadminsComponent } from './subadmins/subadmins.component';
import { CreatesubadminComponent } from './createsubadmin/createsubadmin.component';
import { EditSubadminComponent } from './edit-subadmin/edit-subadmin.component';

const routes: Routes = [
  { path:'',component: SubadminsComponent} ,
  { path: 'addsubadmin', component: CreatesubadminComponent ,data: { animation: 'createsubadmin' } },
  { path: 'editsubadmin', component: EditSubadminComponent ,data: { animation: 'editsubadmin' } },

];

@NgModule({
  imports: [RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class SubadminsRoutingModule { }
