import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { Subject } from 'rxjs';
import { Test } from '../test.model';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private GPS: Subject<Test> = new Subject();

  public getLocation() : Observable<any> {
    return this.GPS.asObservable();
  }

  public setLocation(value: Test) : void {
     this.GPS.next(value);
  }
}
