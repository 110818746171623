import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from "@angular/router";
import { CacheService } from "./cache.service";
import { Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map, catchError, tap } from 'rxjs/operators';


@Injectable()
export class DataService {
  private domain: string;
  public _route: any;
  headers: any;
  
  constructor(private http: HttpClient, private route: Router, public injector: Injector,
    public cacheService: CacheService) {

  }



  getData(url: string): Observable<any> {

    return this.http.get(url)
      .map((res: Response) => {
        return res;
      })
      .catch(error => {
        return Observable.throw(error)
      });

  }

  getDataWithTokens(url: string,params): Observable<any> {
    let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', JSON.parse(localStorage.getItem('Authorization')));
    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    httpHeaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    let options = { headers: httpHeaders }
  
    return this.http.get(url,{headers: httpHeaders, params: params})
      .map((res: Response) => {
        return res;
      })
      .catch(error => {
        return Observable.throw(error)
      });

  }

  deleteDataWithTokens(url: string,params): Observable<any> {
    let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', JSON.parse(localStorage.getItem('Authorization')));
    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    httpHeaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT, DELETE');
    let options = { headers: httpHeaders }
  
    return this.http.delete(url,{headers: httpHeaders, params: params})
      .map((res: Response) => {
        return res;
      })
      .catch(error => {
        return Observable.throw(error)
      });

  }

 

  getDataWithHeaders(url: string): Observable<any> {
    let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', JSON.parse(localStorage.getItem('Authorization')));
    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    httpHeaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    let options = { headers: httpHeaders }
  
    return this.http.get(url,{headers: httpHeaders})
      .map((res: Response) => {
        return res;
      })
      .catch(error => {
        return Observable.throw(error)
      });

  }

  getDataparams(url: string, params: any): Observable<any> {

    let options = {
      headers: this.headers,
      params: params,
    };
    return this.http.get(url, options)
      .map((res: Response) => {
        return res;
      })
      .catch(error => {
        return Observable.throw(error)
      });
  }

  getlistDataTokens(url: string,params): Observable<any> {
    
  
    let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', JSON.parse(localStorage.getItem('Authorization')));
    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    httpHeaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    let options = { headers: httpHeaders }
  
    return this.http.get(url,options)
      .map((res: Response) => {
        return res;
      })
      .catch(error => {
        return Observable.throw(error)
      });

  }


  putDataWithParams(url: string, params: any, ): Observable<any> {
    let options = {
      headers: this.headers,
      params: params,
    };
    return this.http.put(url, null, options)
      .map((res: Response) => {
        return res;
      })
      .catch(error => {
        return Observable.throw(error)
      });
  }
  

  postdataToken(url: string, data): Observable<any> {

    let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', JSON.parse(localStorage.getItem('Authorization')));

    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    httpHeaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    let options = { headers: httpHeaders }

    return this.http.post<any>(url, (data), options).pipe(
      map(
        (res: Response) => {
          return res;
        }),

      catchError(
        this.handleError<any>(''))
    );
  }


  putdataHeaders(url: string, data): Observable<any> {

    let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', JSON.parse(localStorage.getItem('Authorization')));
    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    httpHeaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    let options = { headers: httpHeaders }

    return this.http.put<any>(url, (data), options).pipe(
      map(
        (res: Response) => {
          return res;
        }),

      catchError(
        this.handleError<any>(''))
    );
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  postData(url: string, body: any): Observable<any> {
    return this.http.post(url, body)
      .map(
        (response: Response) => {
          return response;
        }).catch(
          error => {
            console.log(error);
            return Observable.throw(error)
          }
        )
  }

  // putData(urlLookup: string, body: any, objectId?:any) {
  //   let url = this.domain + urlLookup;
  //   let bodyString = body;
  //   if(objectId){
  //     url = url+ objectId +'/?action=put';
  //   }
  //   return this.http.put(url, bodyString).map((res:Response) => (res.json())).catch((err) => this._handleError(err))
  // }
  // deleteData(urlLookup: string, objId: any): Observable<any> {
  //   let url = this.domain + urlLookup;
  //   if (objId) {
  //     url = url + objId + '/';
  //   }
  //   console.log(url)
  //   return this.http.delete(url).map((res:Response) => (res.json())).catch((err) => this._handleError(err))
  // }


}



