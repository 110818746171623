import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes,RouterModule } from '@angular/router';
 
import { AuthModule } from '../auth/auth.module';

const routes: Routes = [   
    
    {path: 'auth', loadChildren: '../auth/auth.module#AuthModule'},
    {path: 'register', loadChildren: '../register/register.module#RegisterModule'},
    {path: 'login', loadChildren: '../pages/login/login.module#LoginModule'},
    // {path: 'updatepwd', loadChildren: '../pages/otpverification/otpverification.module#OtpverificationModule'},
    // {path: 'editor', loadChildren: '../editor/editor.module#EditorModule'},
    { path: 'pages', loadChildren: '../pages/pages.module#PagesModule' },
    {path: 'landingpage', loadChildren: '../pages/landingpage/landingpage.module#LandingpageModule'},
    { path: 'homecomponent', loadChildren: '../homecomponent/homecomponent.module#HomecomponentModule'},
    { path: 'authentication', loadChildren: '../authentication/authentication.module#AuthenticationModule'},
    // { path: 'allorders', loadChildren: '../all-orders/all-orders.module#DezalcomponentsModule'},
    // {path: '**', redirectTo: 'auth/allorders'},
    {path: '**', redirectTo: 'login'},
    // { path: 'allorders', loadChildren: '../allorders/allorders.module#DezalcomponentsModule'},

    { path: 'masterdataupload', loadChildren: '../masterdataupload/masterdataupload.module#MasterdatauploadModule'},
    { path: 'feedbackontactus', loadChildren: '../feedback/feedback.module#FeedbackModule'},
    { path: 'creditforcustomers', loadChildren: '../creditforcustomers/creditforcustomers.module#CreditforcustomersModule'},
    { path: 'report', loadChildren: '../report/report.module#ReportModule'},
    { path: 'subadmins', loadChildren: '../subadmins/subadmins.module#SubadminsModule'},



    // {path: '**', redirectTo: 'authentication/login'},
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class LazyLoadModule { }
