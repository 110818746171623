import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../service/authentication.service';
import { CacheService } from '../service/cache.service';
import { GetListService } from '../service/get-list.service';

@Component({
  selector: 'app-paybalance',
  templateUrl: './paybalance.component.html',
  styleUrls: ['./paybalance.component.scss']
})
export class PaybalanceComponent implements OnInit {
  @Output() paybaloutput = new EventEmitter();

  @Input() inputpaybalorderIDComponent: any;
  @Input() inputbalanceamount: any;

  paymentType= "";
  selectedcardpayment = false;
  unselectedcardpayment = true;
  razorpaypaymentid: any;
  orderid: any;
  balanceamount: any;

  constructor( public cacheService: CacheService,private getListService: GetListService,private auth: AuthenticationService,
    public activeModal: NgbActiveModal,private datePipe: DatePipe,
    ) { }

  ngOnInit() {
    this.orderid=this.inputpaybalorderIDComponent;
    this.balanceamount=String(this.inputbalanceamount.toFixed(2));
    console.log("balanceamount"+this.balanceamount);
    console.log(this.orderid)

  }
  cardfullpaymentclicked() {
    this.unselectedcardpayment = false;
    this.selectedcardpayment = true;
    
    this.paymentType = "Card"

  }


  
  paybalance() {
    var Obj = {
      "orderID": this.orderid
    }
    this.getListService.paybalance(Obj).subscribe(
      rdata => {
        if (rdata) {
          console.log(rdata.data)
// alert("success");
this.paybaloutput.emit(this.orderid);

this.activeModal.close();

        }
        else {
          alert(rdata.message)
        }
      },
      error => {
      }
    )
  }

  ///////////////////// RAZOR PAY CODE STARTS  (PLEASE DONT MODIFY THIS) ///////////////////////////////////////////////////
  options =
    {
      // "key": "rzp_live_vJcXgRC2AImjE4", // Enter the Key ID generated from the Dashboard  
      "key": JSON.parse(localStorage.getItem('razorPayKey')),
      "amount": '', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise  
      "currency": "INR",
      "name": "dezal",
      "description": "Test Transaction",
      "image": "assets/images/app_logo.png",
      "handler": response => {

        this.razorpaypaymentid = response.razorpay_payment_id;
       this.paybalance();
       
        /// need to move to confirmation page on sucess //////

        //alert(response.razorpay_order_id);      
        // alert(response.razorpay_signature)  
      },
      "prefill":
      {
        "name": JSON.parse(localStorage.getItem('loginuserName')),
        "email": JSON.parse(localStorage.getItem('emailID')),
        "contact": JSON.parse(localStorage.getItem('loginuserMobilenumber')),
      },
      "notes":
      {
        "address": "Dezal"
      },
      "theme":
      {
        "color": "#FC5C06"
      }
    };

  razorpay;
  payAmountClicked() {
    if (this.paymentType == "") {
      alert("Please Select Payment Type");
    }
    else if (this.paymentType === "DezalCredit") {
       this.paybalance();
    }
    else {
      this.options.amount = this.balanceamount;
      this.razorpay = new this.auth.nativeWindow.Razorpay(this.options)
      this.razorpay.on('payment.failed',
        function (response) {
          this.razorpaypaymentid = response.razorpay_payment_id;

          ///// need to redirect while failure ///////

          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });
      this.razorpay.open();
    }


  }
  /////////////////////RAZOR PAY CODE ENDS//////////////////////////////////////////////////////

}
