export const Ordermenus = [

    {
        'name': 'All Orders',
        'open': true,
        'link': '/auth/allorders',
    }
    // {
    //     'name': 'Master Data Upload',
    //     'open': true,
    //     'link': '/auth/masterdataupload',
    // },
    // {
    //     'name': 'Configurables',
    //     'open': true,
    //     'link': '/auth/configurables',
    // },
    // {
    //     'name': 'Bowser Enrollment',
    //     'open': true,
    //     'link': '/auth/bowserenrollment',
    // },
    // {
    //     'name': 'Feedback / Contact Us',
    //     'open': true,
    //     'link': '/auth/feedbackontactus',
    // },
    // {
    //     'name': 'Credit for Customers',
    //     'open': true,
    //     'link': '/auth/creditforcustomers',
    // },
    // {
    //     'name': 'Report',
    //     'open': true,
    //     'link': '/auth/report',
    // },
    // {
    //     'name': 'Sub Admins',
    //     'open': true,
    //     'link': '/auth/subadmins',
    // },
     

    
];