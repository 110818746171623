import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  // 139.59.23.82
 
  public ADMIN_BASE_URL = "https://gdepl.co.in/admin/";
  public MASTER_BASE_URL = "https://gdepl.co.in/master/";
  public USER_BASE_URL = "https://gdepl.co.in/user/";
  public  BOWSER_BASE_URL = "https://gdepl.co.in/bowser/";

  public SUPER_ADMIN_LOGIN=this.ADMIN_BASE_URL+"login/v1";
  public EDIT_SUPER_ADMIN=this.ADMIN_BASE_URL+"edit/v1";
  public MINIMUM_ORDER_VALUE_QUANTITY=this.MASTER_BASE_URL+"minimumorder/create/v1";

  public UNASSIGNED_ORDERS_LIST =this.USER_BASE_URL+"order/unassigned/list/v1";
  public CANCELLED_ORDERS_LIST =this.USER_BASE_URL+"order/cancelled/list/v1";
  public CONTACTUS =this.USER_BASE_URL+"contactus/create/v1";
  public ADDSLOTSTIME =this.MASTER_BASE_URL+"timeslots/create/v1";
  public ADDBOOKINGAMOUNT =this.MASTER_BASE_URL+"bookingamount/create/v1";
  public ADDCREDITLIMIT =this.MASTER_BASE_URL+"creditlimit/create/v1";


  public ADD_TAX_PERCENTAGE=this.MASTER_BASE_URL+"tax/create/v1";
  public ADD_INDUSTRY=this.MASTER_BASE_URL+"industry/create/v1";
  public GET_INDUSTRIES_LIST=this.MASTER_BASE_URL+"industry/list/v1";
  public DELETE_INDUSTRY=this.MASTER_BASE_URL+"industry/delete/v1";
  public ADD_PRODUCT=this.MASTER_BASE_URL+"products/create/v1";
  public GET_PRODUCT_LIST=this.MASTER_BASE_URL+"products/list/v1";
  public DELETE_PRODUCT=this.MASTER_BASE_URL+"products/delete/v1";
  public FEEDBACK_LIST =this.USER_BASE_URL+"feedback/list/v1";
  public BOWSER_FEEDBACK_LIST =this.USER_BASE_URL+"feedback/list/v1";
  public FEEDBACK_LIST_DAYS =this.USER_BASE_URL+"feedback/datewise/list/v1";
  public ADD_HOMEPAGE_BANNER =this.MASTER_BASE_URL+"homepagebanners/create/v1";
  public HOMEPAGE_BANNER_LIST =this.MASTER_BASE_URL+"homePageBanners/getby/v1";



  public GET_TOWNS_LIST=this.MASTER_BASE_URL+"town/list/v1";
  public GET_VILLAGE_LIST=this.MASTER_BASE_URL+"town/area/list/v1";
  // public CREATE_BOWSER=this.BOWSER_BASE_URL+"create/v1";
  public CREATE_BOWSER=this.BOWSER_BASE_URL+"create/v2";
  public GET_DISTRICT_LIST=this.MASTER_BASE_URL+"district/list/v1";
  public GET_MANDAL_LIST=this.MASTER_BASE_URL+"district/mandal/list/v1";
  public TOWN_LIST=this.MASTER_BASE_URL+"mandal/town/list/v1";
  public GET_BOWSER_DETAILS=this.BOWSER_BASE_URL+"getbyid/v1";
  public EDIT_BOWSER=this.BOWSER_BASE_URL+"edit/v1";
  public DELETE_BOWSER=this.BOWSER_BASE_URL+"delete/v1";
  public GET_USER_DETAILS=this.ADMIN_BASE_URL+"getbyid/v1";
  public EDIT_BOWSER_VEHICLE=this.BOWSER_BASE_URL+"vehicleedit/v1";


  public SUBADMIN_LIST=this.ADMIN_BASE_URL+"sub/list/v1";
  public CREATE_SUBADMIN=this.ADMIN_BASE_URL+"create/v1";
  public ACCESS_LIST=this.ADMIN_BASE_URL+"access/list/v1";
  public PRIORITY_LIST=this.BOWSER_BASE_URL+"priority/list/v1";
  public INACTIVE_LIST=this.BOWSER_BASE_URL+"inactive/list/v1";
  public BOWSER_SEARCH=this.BOWSER_BASE_URL+"search/list/v1";
  public ALL_CUSTOMERS_LIST=this.USER_BASE_URL+"get/list/v1";
  public CREDIT_ENABLED_LIST=this.USER_BASE_URL+"get/creadit/enabled/list/v1";
  public CREDIT_DISABLED_LIST=this.USER_BASE_URL+"get/creadit/enabled/list/v1";
  public SEARCH_USER=this.USER_BASE_URL+"search/list/v1";
  public ENABLE_DISABLE=this.USER_BASE_URL+"creadit/enabled/v1";
  public DELETE_SUBADMIN=this.ADMIN_BASE_URL+"delete/v1";
  public EDIT_SUBADMIN=this.ADMIN_BASE_URL+"edit/v1";
  public SALES_DOWNLOAD=this.USER_BASE_URL+"sales/export/v1";
  public SETTLEMENT_DOWNLOAD=this.USER_BASE_URL+"settlement/export/v1";
  public CREDITS_DOWNLOAD=this.USER_BASE_URL+"credit/export/v1";

  public TIME_SLOT_LIST=this.MASTER_BASE_URL+"available/timeslots/list/v1";
  public DELETE_TIMESLOT=this.MASTER_BASE_URL+"timeslots/delete/v1";
  public TOWN_LIST_CSV=this.MASTER_BASE_URL+"town/list/v1";
  public ADD_MASTERDATA_CSV=this.MASTER_BASE_URL+"town/csv/v1";

  public GET_MINIMUM_ORDER =this.MASTER_BASE_URL+"minimumorder/getby/v1";
  public GET_TAX_PERCENTAGE =this.MASTER_BASE_URL+"tax/getby/v1";
  public GET_CONTACT_INFO =this.USER_BASE_URL+"contactus/getby/v1";
  public GET_BOOKINGAMOUNT =this.MASTER_BASE_URL+"bookingamount/getby/v1";
  public GET_CREDIT_LIMIT =this.MASTER_BASE_URL+"creditlimit/getby/v1";
  // public BOWSERSLIST=this.BOWSER_BASE_URL+"list/v1";
  // http://139.59.84.224:1111/admin/unassigned/bowser/List/v1?pageNO=0
  // public BOWSERSLIST=this.ADMIN_BASE_URL+"unassigned/bowser/List/v1";
  public ASSIGN_ORDER_TO_BOWSER=this.ADMIN_BASE_URL+"order/assigned/bowser/v1";
  public BOWSERSLIST =this.ADMIN_BASE_URL+"date/unassigned/bowser/List/v1";
  public GET_ALL_ASSIGNED_ORDERSLIST =this.USER_BASE_URL+"order/assigned/list/v1";
  public GET_ALL_COMPLETED_ORDERLIST =this.USER_BASE_URL+"order/completed/list/v1";
  
  public PENDING_ORDER_DATES_COUNT=this.ADMIN_BASE_URL+"order/pending/date/list/v2";
  public VEHICLES_LIST=this.BOWSER_BASE_URL+"getbyvehicle/v1";

  

  public BANNERS_LIST =this.MASTER_BASE_URL+"homePageBanners/getby/v1";


  public ADDRESS_LIST =this.USER_BASE_URL+"get/address/v1";
   public ADD_ADDRESS =this.USER_BASE_URL+"add/address/v1";

   public ADD_FEEDBACK =this.USER_BASE_URL+"feedback/create/v1";
   public PROFILE_DETAILS =this.USER_BASE_URL+"get/userbyid/v1";
   public  EDIT_PROFILE=this.USER_BASE_URL+"editProfile/v1";
   public  DELETE_ADDRESSES =this.USER_BASE_URL+"delete/address/v1";


   public  ORDERS_LIST =this.USER_BASE_URL+"order/list/v1";
   public  GET_ORDER_DETAILS =this.USER_BASE_URL+"order/byid/v1";

   public ORDER_TOTAL_GRAPH =this.USER_BASE_URL+"betweendate/order/total/v1";
   public  ORDER_INVOICE =this.USER_BASE_URL+"order/invoice/export/v1";
   public  CANCEL_ORDER =this.USER_BASE_URL+"order/cancel/v1";
   public  CHANGE_ORDER_DATE =this.USER_BASE_URL+"order/date/change/v1";
   public  SEND_SERVICE_OTP =this.USER_BASE_URL+"service/otp/v1";
   public  MONTHS_LISTING =this.MASTER_BASE_URL+"month/list/v1";
   public   BALANCE_AMOUNT =this.USER_BASE_URL+"pay/order/balnceamount/v1";
   public  ORDERS_DATES =this.USER_BASE_URL+"order/list/v2";
   //////////////////////thabitha/////////////////////////

   public USER_LOGIN =this.USER_BASE_URL+"login/v1";
   public SET_PASSWORD =this.USER_BASE_URL+"createpassword/v1";
   public REGISTRATION =this.USER_BASE_URL+"registration/v2";
   public GETPRICE_DELIVERYCHARGES = this.MASTER_BASE_URL+"town/gettownid/v1";


   public CHECK_MOBILE_NUMBER = this.USER_BASE_URL+"get/byid/v1";
   public ADD_ORDER =this.USER_BASE_URL+"order/create/v1";
   public YEARLY_GRAPH_DETAILS =this.USER_BASE_URL+"yearly/order/list/v1";
   public QUARTERLY_GRAPH_DETAILS =this.USER_BASE_URL+"betweendate/order/list/v2";

   
}