import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from '../service/cache.service';
import { GetListService } from '../service/get-list.service';

@Component({
  selector: 'app-change-order-date',
  templateUrl: './change-order-date.component.html',
  styleUrls: ['./change-order-date.component.scss']
})
export class ChangeOrderDateComponent implements OnInit {
  @Output() changeorderdateoutput = new EventEmitter();
  @Input() inputDataInModalComponent: any;
  @Input() inputorderIDModalComponent: any;


  
  deliverydate: any;
  selectedMonthInDigitsDate: string;
  orderid: any;
  closemodifydialog=true;

  constructor(public activeModal: NgbActiveModal,private datePipe: DatePipe,
    public cacheService: CacheService,private getListService: GetListService) { }
  selectedDate: any;
  datesToMark: any;
  upcomingCalendarEvents: any;
  planModel: any;
  changedSelectedDateFrmt: any;
  selectedFormattedDate: any;
  selectedMonthInStringDate: string;
  selectedYearDate: string;
  ngOnInit() {
    this.deliverydate=this.inputDataInModalComponent;
    console.log(this.deliverydate);
    this.orderid=this.inputorderIDModalComponent;


    this.planModel= {selectedDate: new Date() };
    this.selectedFormattedDate=this.datePipe.transform(this.planModel.selectedDate,'MMMM-dd-yyyy');
    console.log(this.selectedFormattedDate);
    this.selectedMonthInStringDate=this.datePipe.transform(this.planModel.selectedDate,'MMMM');
    console.log(this.selectedMonthInStringDate);

    this.selectedMonthInDigitsDate=this.datePipe.transform(this.planModel.selectedDate,'MM');
    console.log(this.selectedMonthInDigitsDate);

    this.selectedYearDate=this.datePipe.transform(this.planModel.selectedDate,'yyyy');
    console.log(this.selectedYearDate);
  }
  closeclicked(){
    this.activeModal.close();
  }

  minDate = new Date();

  onSelect(event){
    this.selectedDate= event;
   this.selectedFormattedDate=this.datePipe.transform(this.selectedDate,'MMMM-dd-yyyy');
    console.log(this.selectedFormattedDate);
    this.selectedMonthInStringDate=this.datePipe.transform(this.selectedDate,'MMMM');
    console.log(this.selectedMonthInStringDate);
    this.selectedYearDate=this.datePipe.transform(this.selectedDate,'yyyy');


    this.selectedMonthInDigitsDate=this.datePipe.transform(this.selectedDate,'MM');
    console.log(this.selectedMonthInDigitsDate);
  console.log(this.selectedYearDate);
  }



  changeorderDate() {
    var Obj = {
      "orderID":this.orderid,
      "createdDate":this.selectedFormattedDate,
      "createdMonth":this.selectedMonthInDigitsDate,
      "stringMonth":this.selectedMonthInStringDate,
      "createdYear":this.selectedYearDate,
  
  
    }
    this.getListService.modifyOrderDate(Obj).subscribe(
      rdata => {
          if (rdata) {
         
            console.log("empty data check"+rdata.data)
            this.changeorderdateoutput.emit(this.closemodifydialog);

            this.activeModal.close();

         
         
        }
        else {
          alert(rdata)
        }
      },
      error => {
      }
    )
  }

  changeorderclick(){
    this.changeorderDate();
  }


  
}
